import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

export default props => {
    const { course  } = props
    return (
            <Link to={course.url} className="h-full block max-w-md mx-auto overflow-hidden bg-white transition duration-150 hover:shadow-xl shadow rounded-md transform duration-100 scale-100 active:scale-95">
                <div className="h-full flex flex-col">
                    <div className={`bg-gray-200 text-center relative`}>
                        <Img
                            className="absolute inset-0 w-full h-full"
                            fluid={course.cardImg.childImageSharp.fluid}
                            alt="course"
                        />
                    </div>
                    <div className="px-3 py-2 flex-1 flex flex-col">
                        <div className="sm:flex sm:justify-between sm:items-baseline sm:flex-row-reverse">
                            <div className="flex items-center text-gray-400 mt-2 sm:mt-0 text-sm">
                                <svg
                                    className="w-4 h-4"
                                    viewBox="0 0 28 28"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.2167 22.4L18.2 14L23.2167 5.6L25.6667 14L23.2167 21.4ZM12.1333 21.5833L5.83333 15.4H15.8667L20.8833 23.8L12.1333 21.5833ZM12.1333 6.3L20.8833 4.08333L15.8667 12.4833H5.71667C5.71667 12.6 12.1333 6.3 12.1333 6.3ZM24.9667 0L13.5333 2.91667L11.7833 5.83333H8.4L0 14L8.4 22.1667H11.7833L13.4167 25.0833L24.85 28L27.8833 16.9167L26.25 14L28 11.0833L24.9667 0Z" />
                                </svg>
                                <span className="pl-1 font-bold ">Unity</span>
                            </div>
                            <h2 className="py-2 text-xl font-bold text-gray-800">{course.title}</h2>
                        </div>
                        <p className="text-gray-800 text-sm flex-1 pb-4">{course.description}</p>
                    </div>
                </div>
            </Link>
    )
}
