import React from 'react'
import { Link, graphql } from 'gatsby'
import CourseCard from '../components/course-card.js'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import './test.css'
// import carouselImg from '../images/Carousel.png'

const IndexPage = props => {
    const carouselImg = props.data.file
    return (
        <Layout location={props.location}>
            <SEO
                title="IndieNuggets"
                description="Advanced game dev courses and bite size tutorials"
            />

            <div className="relative z-0">
                <header className="hidden sm:block pointer-events-none absolute inset-0 -mt-10 opacity-5">
                    <div className="stripes h-full w-full overflow-hidden opacity-75 xl:opacity-50" aria-hidden={true}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </header>

                <div className="relative mt-6 z-10 ">
                    <div className="sm:hidden mt-3">
                        <Img fluid={carouselImg.childImageSharp.fluid} alt="Courses carousel" />
                    </div>
                    <div className="py-4 sm:pt-32 sm:pb-24 max-w-6xl mx-auto md:flex md:items-center md:justify-center">
                        <div className="flex-1">
                            <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="">
                                    <h1 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                                        Learn Game Dev.
                                        <br />
                                        Beyond the Basics.
                                    </h1>
                                </div>

                                <div className="mt-8 sm:mt-10">
                                    <ul className="">
                                        <li>
                                            <div className="flex">
                                                <div className="flex-shrink-0">
                                                    <div className="flex items-center justify-center h-8 w-8 rounded-md bg-teal-200 text-teal-800">
                                                        <svg
                                                            className="h-4 w-4"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            viewBox="0 0 24 24">
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                                d="M13 10V3L4 14h7v7l9-11h-7z"
                                                            />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="ml-4 flex-center">
                                                    <h5 className="sm:text-lg leading-6 font-medium text-teal-800">
                                                        High Quality Game Dev Courses
                                                    </h5>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="mt-5 md:mt-4">
                                            <div className="flex">
                                                <div className="flex-shrink-0">
                                                    <div className="flex items-center justify-center h-8 w-8 rounded-md bg-teal-200 text-teal-800">
                                                        <svg
                                                            className="h-4 w-4"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            viewBox="0 0 24 24">
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                                d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9"
                                                            />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="ml-4 flex-center">
                                                    <h5 className="sm:text-lg leading-6 font-medium text-teal-800">
                                                        Learn by Building Real World Projects
                                                    </h5>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="mt-5 md:mt-4">
                                            <div className="flex">
                                                <div className="flex-shrink-0">
                                                    <div className="flex items-center justify-center h-8 w-8 rounded-md bg-teal-200 text-teal-800">
                                                        <svg
                                                            className="h-4 w-4"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            viewBox="0 0 24 24">
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                                d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                                                            />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="ml-4 flex-center">
                                                    <h5 className="sm:text-lg leading-6 font-medium text-teal-800">
                                                        Chat Support & Discord Community
                                                    </h5>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="mt-8 md:mt-10">
                                            <Link
                                                to="/courses"
                                                className="group flex justify-center sm:inline-flex items-center text-center border sm:px-0 py-4 bg-gray-800 text-white text-sm font-display font-semibold rounded-md shadow-md  hover:bg-gray-700 transform scale-100 duration-100 active:scale-90 focus:bg-gray-900 focus:outline-none focus:shadow-outline sm:text-sm sm:leading-5">
                                                <span className="mr-4 sm:ml-12">
                                                    Browse Courses
                                                </span>
                                                <svg
                                                    className="transform duration-150 group-hover:translate-x-2 w-5 h-5 sm:mr-12"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor">
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                                                    />
                                                </svg>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="hidden sm:block relative flex-1 w-full h-full">
                            <Img
                                className="absolute inset-0 h-full w-full"
                                fluid={carouselImg.childImageSharp.fluid}
                                alt="Courses carousel"
                            />
                        </div>
                    </div>

                    <div className="px-2 mt-4 flex-1 max-w-6xl mx-auto">
                        <h1 className="font-bold text-lg pl-2 sm:pl-6">Latest Courses</h1>
                        <ul className="mt-4 sm:mt-0 sm:flex sm:justify-center">
                            {props.data.allCourse.edges.map((e, i) => {
                                return (
                                    <li key={e.node.id} className="sm:mx-4 mt-6 sm:mt-3 sm:mb-6">
                                        <CourseCard course={e.node} index={i} />
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default IndexPage

export const query = graphql`
    query IndexQuery {
        allCourse(filter:{public:{eq:true}},sort: { fields: releaseDate, order: DESC }, limit: 2) {
            edges {
                node {
                    id
                    title
                    price
                    description
                    status
                    url
                    difficulty
                    videoDuration
                    episodeCount
                    cardImg {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
        file(name: { eq: "Carousel" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
